import React from 'react'

const ScheduleClinic = () => {
  return (
    <div className='mx-2'>
      <div className="my-4  font-semibold ">
        <p className="text-[18px]">Schedule Referee Clinic</p>
        <p className="text-[13px]">Schedule may be subject to changes!</p>
      </div>
      <div>Coming Soon.......</div>
    </div>
  )
}

export default ScheduleClinic
